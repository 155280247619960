<template>
  <div class="discover-soursce-filter-container">
    <h1 class="discover-soursce-filter-container__title">Search Filters</h1>
    <div @click.stop="closeSourcesDropDown()">
    <a-tabs
      v-model:activeKey="activeKey"
      class="discover-soursce-filter-container__discover-soursce-filter-tabs"
    >
      <template #tabBarExtraContent>
        <h1 class="clear-filter-btn" @click="clearAllFilters()" >Clear Filters</h1>
      </template>
      <a-tab-pane key="pdbNewsSources" tab="News Sources">
        <div class="discover-soursce-filter">
          <div class="discover-soursce-filter__body">
            <h1 class="clear-filter-btn-mobile" @click="clearAllFilters()" >Clear Filters</h1>
            <a-row :gutter="[40]">
              <a-col
                :xs="24"
                :sm="24"
                :md="12"
                :lg="6"
                :xl="6"
                class="discover-soursce-filter__body--included-excluded-sources-container"
              >
                <div class="input-field">
                  <p class="title">Include Sources</p>
                  <pdb-news-sources
                    :source="'include'"
                    :closeDropDown="closeDropDown"
                  ></pdb-news-sources>
                </div>
              </a-col>
              <a-col
                :xs="24"
                :sm="24"
                :md="12"
                :lg="6"
                :xl="6"
                class="discover-soursce-filter__body--included-excluded-sources-container"
              >
                <div class="input-field">
                  <p class="title">Exclude Sources</p>
                  <pdb-news-sources
                    :source="'exclude'"
                    :closeDropDown="closeDropDown"
                  ></pdb-news-sources>
                </div>
              </a-col>
              <a-col 
                :xs="24"
                :sm="24"
                :md="12"
                :lg="6"
                :xl="6"
                class="discover-soursce-filter__body--date-filters-container"
                >
                <div class="input-field">
                  <p class="title">Date Range</p>
                  <pdb-date-filters>
                  </pdb-date-filters>
                </div>
                <button @click="searchNewsArticles()" class="apply-btn">Apply Filters</button> 
              </a-col>
              <a-col
                :xs="24"
                :sm="24"
                :md="12"
                :lg="6"
                :xl="6"
                class="discover-soursce-filter__body--explore-newly-container"
              >
                <!-- <p>Explore newly added sources</p>
                <div class="result-container">
                  <p v-if="PDBNewlyAddedASources.length === 0" class="message">No sources found</p>
                  <button class="result" 
                    v-for="(source, index) in PDBNewlyAddedASources" 
                    :key="index"
                    @click="selectNewSource(source.key, source.doc_count, true)">
                    {{ source.key }} ({{ source.doc_count}})
                  </button>
                </div>
                <button @click="visibleRequestModal()" class="request-new-source-btn">
                  <i class="icon icon-add_plus"></i> Request new source
                </button> -->
                <h1 class="title">Explore newly added sources</h1>
                <request-news-source :requestType="'news source'"></request-news-source>
              </a-col>
            </a-row>
          </div>
        </div>
      </a-tab-pane>
      <a-tab-pane key="insightsSources" tab="Research Publications">
        <div class="discover-soursce-filter">
          <div class="discover-soursce-filter__body">
            <h1 class="clear-filter-btn-mobile" @click="clearAllFilters()" >Clear Filters</h1>
            <a-row :gutter="[40]">
              <a-col
                :xs="24"
                :sm="24"
                :md="12"
                :lg="6"
                :xl="6"
                class="discover-soursce-filter__body--included-excluded-sources-container"
              >
                <div class="input-field">
                  <p class="title">Included Sources</p>
                  <insights-news-sources
                  :source="'include'"
                  :closeDropDown="closeDropDown"
                  >
                  </insights-news-sources>
                </div>
              </a-col>
              <a-col
                :xs="24"
                :sm="24"
                :md="12"
                :lg="6"
                :xl="6"
                class="discover-soursce-filter__body--included-excluded-sources-container"
              >
                <div class="input-field">
                  <p class="title">Excluded Sources</p>
                  <insights-news-sources
                  :source="'exclude'"
                  :closeDropDown="closeDropDown"
                  >
                  </insights-news-sources>
                </div>
              </a-col>
              <a-col 
                :xs="24"
                :sm="24"
                :md="12"
                :lg="6"
                :xl="6"
                class="discover-soursce-filter__body--date-filters-container"
                >
                <div class="input-field">
                  <p class="title">Date Range</p>
                  <insights-date-filters>
                  </insights-date-filters>
                </div>
                <button @click="searchInsightsArticles()" class="apply-btn">Apply Filters</button>
              </a-col>
              <a-col
                :xs="24"
                :sm="24"
                :md="12"
                :lg="6"
                :xl="6"
                class="discover-soursce-filter__body--explore-newly-container"
              >
                <!-- <p>Explore newly added sources</p>
                <div class="result-container">
                  <p v-if="InsightsNewlyAddedASources.length === 0" class="message">No sources found</p>
                  <button class="result" 
                    v-for="(source, index) in InsightsNewlyAddedASources" 
                    :key="index"
                    @click="selectNewSource(source.key, source.doc_count, false)">
                    {{ source.key }} ({{ source.doc_count}})
                  </button>
                </div>
                <button @click="visibleRequestModal()" class="request-new-source-btn">
                  <i class="icon icon-add_plus"></i> Request new source
                </button> -->
                <h1>Explore newly added sources</h1>
                <request-news-source :requestType="'research institute'"></request-news-source>
              </a-col>
            </a-row>
          </div>
        </div>
      </a-tab-pane>
    </a-tabs>
    </div>
  </div>
</template>

<script>
import PdbNewsSources from "./PDBNewsSources.vue";
import InsightsNewsSources from "./InsightsNewsSources.vue";
import PdbDateFilters from "./dateFilters/PDBDateFilters.vue";
import InsightsDateFilters from "./dateFilters/InsightsDateFilters.vue";
import RequestNewsSource from '../RequestNewsSource.vue';
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    PdbNewsSources,
    InsightsNewsSources,
    PdbDateFilters,
    InsightsDateFilters,
    RequestNewsSource
  },
  computed: {
    ...mapGetters({
      selectedModule: 'discover/selectedModule',
    }),
    allNewsSources() {
      return this.$store.getters["discover/getSources"];
    },
    PDBNewlyAddedASources(){
      return this.allNewsSources.filter(item => item.is_newly_added);
    },
    InsightsNewlyAddedASources(){
      return this.allNewsSources.filter(item => item.is_newly_added);
    },
    getSortFilter() {
      return this.$store.getters["discover/getSortFilter"];
    },
    searchTopic() {
      return this.$store.getters["discover/getSearchedTopic"];
    },
  },
  data() {
    return {
      activeKey: 'pdbNewsSources',
      requestNewsSourceModal: false,
      closeDropDown: false
    };
  },
  watch:{
    selectedModule:{
      handler(val){
          this.activeKey = val === 'Trending News' || val === 'news-tab' ? 'pdbNewsSources' : 'insightsSources'
      },
      immediate:true
    }
  },
  methods: {
    ...mapActions({
      setSelectedModule: 'discover/setSelectedModule'
    }),
    async searchNewsArticles() {
      this.$store.commit("discover/SET_ARTICLES_PAGE_OFFSET", 0);
      this.closeDrawer()
      await this.$store.dispatch("discover/searchArticles");
    },
    async searchInsightsArticles() {
      this.$store.commit("discover/SET_INSIGHTS_PAGE_OFFSET", 0);
      this.closeDrawer()
      await this.$store.dispatch("discover/searchInsights");
    },
    clearAllFilters(){
      this.emitter.emit("clearFilters", true);
      this.closeSourcesDropDown()
      this.clearResearchFilter()
      this.clearNewsFilter()
      this.closeDrawer()
      this.searchNewsArticles();
      this.searchInsightsArticles();
    },
    async selectNewSource(source, docCount, isInclude) {
      const sourceIndex = this.allNewsSources.findIndex(
        (s) => s.key === source
      );
      if (sourceIndex !== -1) {
        this.allNewsSources.splice(sourceIndex, 1);
        const newItem = {
          key: source,
          doc_count: docCount,
        };
        let count = this.$store.getters['discover/getFilterCounts'];
        count = count + 1;
        this.$store.commit('discover/SET_FILTERS_COUNT', count);
        await this.$store.commit("discover/SET_ALL_SOURCES", [...this.allNewsSources]);
        if (isInclude) {
          this.$store.commit("discover/SET_INCLUDED_SOURCES",  newItem);
        } else {
          this.$store.commit("discover/SET_EXCLUDED_SOURCES",  newItem);
        }
      }
    },
    visibleRequestModal() {
      this.requestNewsSourceModal = true 
    },
    closeRequestNewsSourceModal() {
      this.requestNewsSourceModal = false;
    },
    closeSourcesDropDown(){
      this.closeDropDown = !this.closeDropDown;
      this.emitter.emit('closeTopicBar', false);
    },
    clearResearchFilter(){
      this.emitter.emit("clearInsightsFilters", true);
      this.$store.commit("discover/SET_INSIGHTS_INCLUDED_SOURCES", "");
      this.$store.commit("discover/SET_INSIGHTS_EXCLUDED_SOURCES", "");
      this.$store.commit('discover/SET_FILTERS_COUNT', 0);
      this.$store.dispatch("discover/getAllInsightsSources");
    },
    clearNewsFilter(){
      this.emitter.emit("clearNewsFilters", true);
      this.$store.commit("discover/SET_INCLUDED_SOURCES", "");
      this.$store.commit("discover/SET_EXCLUDED_SOURCES", "");
      this.$store.commit('discover/SET_FILTERS_COUNT', 0);
      this.$store.dispatch("discover/getAllSources");
    },
    closeDrawer(){
      this.$emit('closeDrawer')
    }
  },
};
</script>

<style lang="scss">
.discover-soursce-filter-container {
  background-color: #000000bf;
  height: 100vh;
  position: absolute;
  width: 100%;
  &__title {
    position: absolute;
    font-size: 1.8rem;
    font-family: $font-primary-bold;
    margin-bottom: 0;
    line-height: normal;
    padding: 0 3rem 0;
    top: 2.5rem;
    z-index: 4;
    @include respond(laptop-small) {
      top: 3rem;
    }
    @include respond(tab-port) {
      top: 2.8rem;
    }
    @include respond(phone-x-small) {
      top: 3.2rem;
    }
  }
  &__discover-soursce-filter-tabs {
    background-color: #f8f8ff;
    &--title {
      font-size: 1.6rem;
      font-family: $font-primary-bold;
      margin-bottom: 0;
      width: fit-content;
      margin-top: 2.6rem;
      padding: 0 3rem;
    }
    .ant-tabs-bar {
      padding: 2.5rem 3rem 0;
      margin-bottom: 0;
      border-bottom: 1px solid #c5c6cc;
      display: none !important;
      .ant-tabs-extra-content {
        line-height: normal;
        float: right !important;
        .clear-filter-btn {
          font-size: 1.4rem;
          font-family: $font-primary-bold;
          margin-bottom: 0;
          margin-left: 0;
          margin-top: 0.7rem;
          cursor: pointer;
        }
        @include respond(tab-port) {
          display: none;
        }
      }
      .ant-tabs-nav-container {
        margin-left: 19.5rem;
        @include respond(tab-port) {
          margin-left: 12rem;
        }
        .ant-tabs-nav-wrap {
          .ant-tabs-nav-scroll {
            .ant-tabs-nav {
              .ant-tabs-tab {
                padding: 0 0 1.4rem;
                font-size: 1.4rem;
                font-family: $font-primary;
                opacity: 1;
                color: $color-black;
                margin-right: 3rem;
              }
              .ant-tabs-tab-active {
                font-family: $font-primary-medium;
                opacity: 1;
              }
              .ant-tabs-ink-bar {
                background-color: $color-primary;
                height: 0.3rem;
              }
            }
          }
        }
      }
    }
    .ant-tabs-content {
      border-top: 1px solid #c5c6cc;
      .ant-tabs-tabpane {
        overflow-y: auto;
        max-height: 60vh;
        padding: 0 3rem;
        .discover-soursce-filter {
          background-color: #f8f8ff;
          &__body {
            background-color: $color-white;
            .clear-filter-btn-mobile {
              font-size: 2rem;
              font-family: $font-primary-bold;
              margin-bottom: 0;
              margin-left: 0;
              margin-top: 2rem;
              cursor: pointer;
              margin-left: auto;
              display: none;
              width: fit-content;
              @include respond(tab-port) {
                display: block;
              }
            }
            &--included-excluded-sources-container {
              position: relative;
              padding: 2.1rem 0;
              background-color: #f8f8ff;
              .input-field {
                .title {
                  font-size: 1.4rem;
                  font-family: $font-primary-medium;
                  font-weight: 500;
                  margin-bottom: 1.6rem;
                  line-height: 1.6rem;
                  width: fit-content;
                }
                input {
                  border-radius: 5px !important;
                  font-size: 1.4rem;
                  border: 1px solid #c5c6cc;
                  font-family: $font-primary;
                  width: 100%;
                  height: 4rem;
                  padding: 1rem;
                  outline: none;
                  &::placeholder {
                    opacity: 0.4;
                  }
                }
              }
              .selected-sources {
                margin: 3rem 0 4rem;
                p {
                  font-size: 1.4rem;
                  font-family: $font-primary-bold;
                  margin-bottom: 1.6rem;
                  width: fit-content;
                }
                .select-container {
                  overflow-y: auto;
                  max-height: 8rem;
                  height: 100%;
                  .select {
                    box-sizing: border-box;
                    margin: 0 0.8rem 0.8rem 0.4rem;
                    border-radius: 100px !important;
                    color: #000000d9;
                    font-variant: tabular-nums;
                    list-style: none;
                    font-feature-settings: tnum;
                    display: inline-block;
                    height: 3rem;
                    padding: 0.5rem 1.6rem;
                    white-space: nowrap;
                    background-color: #d0dbff;
                    border: 1px solid #3764fb;
                    opacity: 1;
                    transition: all 0.3s;
                    position: relative;
                    padding-right: 3rem;
                    overflow: hidden;
                    .source {
                      display: flex;
                      align-items: center;
                      .logo{ 
                        width: 1.5rem;
                        height: 1.5rem;
                        margin-right: 0.5rem;
                        margin-bottom: 0.2rem;
                        object-fit: contain;
                        border-radius: none;
                      }
                      .border-radius {
                        border-radius: 100% !important;
                      }
                      p {
                        margin-right: 0.5rem;
                        margin-bottom: 0;
                        font-size: 1.2rem;
                        font-family: $font-primary;
                        line-height: 1.4rem;
                      }
                    }
                    
                    .icon {
                      padding: 0.5rem;
                      background-color: $color-white;
                      border-radius: 100%;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      font-size: 0.8rem;
                      transform: rotate(45deg);
                      cursor: pointer;
                      width: fit-content;
                      top: 50%;
                      position: absolute;
                      transform: translateY(-50%) rotate(45deg);
                      right: 0.8rem;
                      width: 1.8rem;
                      height: 1.8rem;
                      margin: 0;
                    }
                  }
                }
                .select-container::-webkit-scrollbar-track {
                  background-color: #ffffff3f;
                }
                .select-container::-webkit-scrollbar {
                  width: 4px;
                  background-color: rgb(224, 224, 224);
                  border-radius: 3rem;
                }
                .select-container::-webkit-scrollbar-thumb {
                  background-color: $color-primary;
                  border-radius: 3rem;
                }
              }
              &::before {
                content: "";
                position: absolute;
                background-color: #c5c6cc;
                height: 100%;
                width: 1px;
                right: 0;
                top: 0;
                @include respond(phone-x-small) {
                  content: none;
                }
              }
            }
            &--date-filters-container {
              padding: 2.1rem 0;
              position: relative;
              background-color: #f8f8ff;
              @include respond(laptop-small) {
                border-top: 1px solid #c5c6cc;
              }
              .input-field {
                .title {
                  font-size: 1.4rem;
                  font-family: $font-primary-medium;
                  margin-bottom: 1.6rem;
                  width: fit-content;
                  line-height: 1.6rem;
                }
              }
              &::before {
                content: "";
                position: absolute;
                background-color: #c5c6cc;
                height: 100%;
                width: 1px;
                right: 0;
                top: 0;
                @include respond(phone-x-small) {
                  content: none;
                }
              }
              .apply-btn {
                border: none;
                outline: none;
                background-color: $color-primary;
                font-size: 1.6rem;
                color: $color-white;
                width: max-content;
                padding: 0 4rem;
                height: 3.9rem;
                font-family: $font-primary-medium;
                margin-left: auto;
                display: block;
                margin-top: auto;
                margin-bottom: 0.4rem;
                cursor: pointer;
                position: absolute;
                bottom: 2.1rem;
                right: 20px;
                @include respond(phone-x-small) {
                  position: inherit;
                  bottom: inherit;
                  right: inherit;
                }
              }
            }
            &--explore-newly-container {
              padding: 2.1rem 0;
              position: relative;
              background-color: #f8f8ff;
              @include respond(laptop-small) {
                border-top: 1px solid #c5c6cc;
              }
              h1 {
                font-size: 1.4rem;
                font-family: $font-primary-medium;
                margin-bottom: 1.6rem;
                width: fit-content;
                line-height: 1.6rem;
              }
              .request-news-source-container {
                .ant-form {
                  .ant-btn {
                    font-size: 1.6rem;
                    height: 3.9rem;
                    width: 16.9rem;
                  }
                }
              }
              // .result-container {
              //   overflow-y: auto;
              //   overflow-x: hidden;
              //   max-height: 9rem;
              //   .message {
              //     text-align: center;
              //     margin: auto;
              //     font-family: $font-primary;
              //     text-align: center;
              //     width: 100%;
              //   }
              //   .result {
              //     box-sizing: border-box;
              //     margin: 0 0.8rem 0.8rem 0;
              //     border-radius: 100px !important;
              //     color: #000000d9;
              //     font-variant: tabular-nums;
              //     list-style: none;
              //     font-feature-settings: tnum;
              //     display: inline-block;
              //     height: 2.4rem;
              //     padding: 0 1.6rem;
              //     white-space: nowrap;
              //     background-color: #e8e8e8;
              //     border: 1px solid #3764fb;
              //     opacity: 1;
              //     transition: all 0.3s;
              //     position: relative;
              //     overflow: auto;
              //     font-size: 1.2rem;
              //     font-family: $font-primary;
              //     line-height: 1.4rem;
              //     color: $color-black;
              //     vertical-align: middle;
              //     padding-bottom: 0.1rem;
              //   }
              // }
              // .result-container::-webkit-scrollbar-track {
              //   background-color: #ffffff3f;
              // }
              // .result-container::-webkit-scrollbar {
              //   width: 4px;
              //   background-color: rgb(224, 224, 224);
              //   border-radius: 3rem;
              // }
              // .result-container::-webkit-scrollbar-thumb {
              //   background-color: $color-primary;
              //   border-radius: 3rem;
              // }
              // .request-new-source-btn {
              //   display: flex;
              //   align-items: center;
              //   width: fit-content;
              //   font-size: 1.4rem;
              //   font-family: $font-primary-medium;
              //   margin-bottom: 6.3rem;
              //   background-color: transparent;
              //   height: fit-content;
              //   border: none;
              //   outline: none;
              //   margin-top: 2.2rem;
              //   cursor: pointer;
              //   line-height: 1.6rem;
              //   .icon {
              //     margin-right: 1.2rem;
              //     font-size: 1.2rem;
              //     margin-bottom: 0.2rem;
              //   }
              // }
            }
          }
        }
      }
      .ant-tabs-tabpane::-webkit-scrollbar-track {
        background-color: #ffffff3f;
      }
      .ant-tabs-tabpane::-webkit-scrollbar {
        width: 4px;
        background-color: rgb(224, 224, 224);
        border-radius: 3rem;
      }
      .ant-tabs-tabpane::-webkit-scrollbar-thumb {
        background-color: $color-primary;
        border-radius: 3rem;
      }
    }
  }
}
</style>
