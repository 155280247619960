<template>
  <div class="all-news-sources-dropdown">
    <input
      type="text"
      placeholder="Search for a topic of your choice."
      @focus="visibleSourcesDropdown()"
      v-model="searchSource"
      @input="filterSources"
      class="all-news-sources-dropdown__input"
    />
    <div v-if="openSources" class="all-news-sources-dropdown__dropdown">
      <div class="all-news-sources-dropdown__dropdown--loader" v-if="sourcesLoading">
        <the-loader/>
      </div>
      <p v-if="!sourcesLoading && filteredSources.length === 0" class="message">No sources found.</p>
      <p
        v-for="source in filteredSources"
        :key="source"
        @click="selectSource(source?.source_name, source.logo_url , source.link)"
      >
        {{ source?.source_name ? source?.source_name.toUpperCase() : '' }}
      </p>
    </div>
    <div v-if="source === 'include'" class="all-news-sources-dropdown__selected-sources">
      <p>Selected Sources</p>
      <div class="all-news-sources-dropdown__selected-sources--select-container" :class="{'select-sources-container': getSelectedIncludeSources.length > 0}">
        <button v-if="getSelectedIncludeSources.length > 0" @click="clearIncludedSources" class="close-btn"><i class="icon icon-add_plus"></i></button>
        <p class="message" v-if="getSelectedIncludeSources.length === 0">No selected source</p>
        <button
          class="select"
          v-for="(source, index) in getSelectedIncludeSources"
          :key="index"
        >
          <div class="source">
            <img v-if="source.logo_url" :class="{'border-radius' : source.logo_url }" class="logo" :src="source.logo_url" />
            <img v-else class="logo" src="@/assets/icons/newspaper-black.svg" />
            <p>{{ source?.source_name ? source?.source_name.toUpperCase() : '' }}</p>
          </div>
          <i @click.stop="removeSelectedIncludeSource(index)" class="icon icon-add_plus"></i>
        </button>
      </div>
    </div>
    <div v-if="source === 'exclude'" class="all-news-sources-dropdown__selected-sources">
      <p>Selected Sources</p>
      <div class="all-news-sources-dropdown__selected-sources--select-container"  :class="{'select-sources-container': getSelectedExcludeSources.length > 0}">
        <button v-if="getSelectedExcludeSources.length > 0" @click="clearExcludedSources()" class="close-btn"><i class="icon icon-add_plus"></i></button>
        <p class="message" v-if="getSelectedExcludeSources.length === 0">No selected source</p>
        <button
          class="select"
          v-for="(source, index) in getSelectedExcludeSources"
          :key="index"
        >
          <div class="source">
            <img v-if="source.logo_url" :class="{ 'border-radius': source.logo_url }"  class="logo" :src="source.logo_url" />
            <img v-else class="logo" src="@/assets/icons/newspaper-black.svg" />
            <p>{{ source?.source_name ? source?.source_name.toUpperCase() : '' }}</p>
          </div>
          <i @click.stop="removeSelectedExcludeSource(index)" class="icon icon-add_plus"></i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import TheLoader from "../../BaseComponents/TheLoader.vue";
import {mapGetters, mapActions} from "vuex"
export default {
  components: {
    TheLoader
  },
  props: {
    source: String,
    closeDropDown: Boolean
  },
  mounted() {
    // console.log("ALL NEWS SOURCE",this.allNewsSources)
    document.addEventListener('click', this.handleClickOutside);
  },
  computed: {
    ...mapGetters({
      stateActiveTopicTab: 'discover/activeTopicTab',
      trendingNewsFilters: 'discover/trendingNewsFilters'
    }),
    sourcesLoading() {
      return this.$store.getters["discover/getPDBSourcesLoader"]
    },
    allNewsSources() {
      return this.$store.getters["discover/getSources"];
    },
    getSelectedIncludeSources: {
      get(){
        return this.stateActiveTopicTab === 'Trending news' ? this.trendingNewsFilters?.includedSources : this.$store.getters['discover/getIncludedSelectedSources'];
      },
      set(val){
        this.stateActiveTopicTab === 'Trending news' ? this.setTrendingNewFilters({includedSources: val}) : this.$store.commit("discover/SET_INCLUDED_SOURCES", val)
      }
    },
    getSelectedExcludeSources: {
      get(){
        return this.stateActiveTopicTab === 'Trending news' ? this.trendingNewsFilters?.excludedSources : this.$store.getters['discover/getExcludedSelectedSources'];
      },
      set(val){
        this.stateActiveTopicTab === 'Trending news' ? this.setTrendingNewFilters({excludedSources: val}) : this.$store.commit("discover/SET_EXCLUDED_SOURCES", val)
      }
    },
  },
  watch:{
    closeDropDown(){
      this.openSources = false;
    },
    getSelectedIncludeSources:{
      handler(array){
        const includedSources = array.map(source=>source?.source_name)
        const excludedSources = this.getSelectedExcludeSources.map(source=>source?.source_name)
        this.filteredSources = [...this.allNewsSources.filter(source => !includedSources.includes(source?.source_name) && !excludedSources.includes(source?.source_name) )]
      },immediate: true
    },
    getSelectedExcludeSources:{
      handler(array){
        const includedSources = this.getSelectedIncludeSources.map(source=>source?.source_name)
        const excludedSources = array.map(source=>source?.source_name)
        this.filteredSources = [...this.allNewsSources.filter(source => !includedSources.includes(source?.source_name) && !excludedSources.includes(source?.source_name) )]
      },immediate: true
    }
 
  },
  data() {
    return {
      openSources: false,
      searchSource: "",
      filteredSources: [],
      selectedIncludeSources: [],
      selectedExcludeSources: [],
      
    };
  },
  methods: {
    ...mapActions({
      setTrendingNewFilters: 'discover/setTrendingNewFilters'
    }),
    handleClickOutside(event) {
      const element = this.$el; 
      
      if (!element.contains(event.target)) {
        this.openSources = false; 
        }
      
    },
    visibleSourcesDropdown() {
      setTimeout(() => {
        this.openSources = true;
      }, 200);
    },
    async selectSource(source, icon, link) {
      this.openSources = false;
      const sourceIndex = this.allNewsSources.findIndex(
        (s) => s.source_name === source
      );
      if (sourceIndex !== -1) {
        const newItem = {
          source_name: source,
          logo_url: icon,
          link: link,
        };
        let count = this.$store.getters['discover/getFilterCounts'];
        count = count + 1;
        this.$store.commit('discover/SET_FILTERS_COUNT', count);
        if (this.source === "include") {
          this.selectedIncludeSources = [...this.getSelectedIncludeSources]
          this.selectedIncludeSources.push(newItem);
          this.getSelectedIncludeSources = this.selectedIncludeSources
          this.$store.commit("discover/SET_TOPIC_SOURCE", false);
        } else {
          this.selectedExcludeSources = [...this.getSelectedExcludeSources]
          this.selectedExcludeSources.push(newItem);
          this.getSelectedExcludeSources = this.selectedExcludeSources 
        }
      }
    },
    removeSelectedIncludeSource(index) {
      let count = this.$store.getters['discover/getFilterCounts'];
      count = count - 1;
      this.$store.commit('discover/SET_FILTERS_COUNT', count);
      const removedSource = this.getSelectedIncludeSources[index];
      this.getSelectedIncludeSources.splice(index, 1);

      const updatedSourcesList = this.getSelectedIncludeSources.filter((val) => {
          return val.source_name !== removedSource.source_name;
      });

      this.getSelectedIncludeSources = updatedSourcesList;

    },
    clearIncludedSources() {
      let count = this.$store.getters['discover/getFilterCounts'];
      count = count - this.getSelectedIncludeSources.length;
      this.$store.commit('discover/SET_FILTERS_COUNT', count);
      this.getSelectedIncludeSources.map((val) => {
        this.allNewsSources.push(val);
      });
      this.getSelectedIncludeSources = [];
    },
    removeSelectedExcludeSource(index) {
      let count = this.$store.getters['discover/getFilterCounts'];
      count = count - 1;
      this.$store.commit('discover/SET_FILTERS_COUNT', count);
      const removedSource = this.getSelectedExcludeSources[index];
      this.getSelectedExcludeSources.splice(index, 1);

      const updatedSourcesList = this.getSelectedExcludeSources.filter((val) => {
          return val.source_name !== removedSource.source_name;
      });

      this.getSelectedExcludeSources = updatedSourcesList;
    },
    clearExcludedSources() {
      let count = this.$store.getters['discover/getFilterCounts'];
      count = count - this.getSelectedExcludeSources.length;
      this.$store.commit('discover/SET_FILTERS_COUNT', count);
      this.getSelectedExcludeSources.map((val) => {
        this.allNewsSources.push(val);
      });
      this.getSelectedExcludeSources = [];
    },
    filterSources(){
      if(this.searchSource === ""){
        this.filteredSources = [...this.allNewsSources]
      }
      this.filteredSources = this.allNewsSources.filter((source) =>
        source.source_name.toLowerCase().startsWith(this.searchSource.toLowerCase())
      );
    }
  },
};
</script>

<style lang="scss">
.all-news-sources-dropdown {
  position: relative;
  &__input {
    border-radius: 5px !important;
    font-size: 1.4rem;
    border: 1px solid #c5c6cc;
    font-family: $font-primary;
    width: 100%;
    height: 4rem;
    padding: 1rem;
    outline: none;
    &::placeholder {
      opacity: 0.4;
    }
  }
  &__dropdown {
    position: absolute;
    width: 100%;
    background-color: $color-white;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    border-end-end-radius: 0.5rem;
    border-end-start-radius: 0.5rem;
    max-height: 12rem;
    overflow-y: auto;
    z-index: 2;
    .message{
      cursor: default;
    }
    &--loader {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 1rem 0 !important;
      .ant-spin {
        .anticon {
          font-size: 2rem !important;
        }
      }
    }
    p {
      margin: 0 !important;
      padding: 1rem;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      font-size: 1.6rem;
      font-family: $font-primary-medium;
      line-height: normal;
      border-bottom: 1px solid $color-dark-grey-5;
      cursor: pointer;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 1; /* number of lines to show */
      line-clamp: 1;
      -webkit-box-orient: vertical;
      &:last-child {
        border-bottom: none;
      }
    }
  }
  &__dropdown::-webkit-scrollbar-track {
    background-color: #ffffff3f;
  }
  &__dropdown::-webkit-scrollbar {
    width: 4px;
    background-color: rgb(224, 224, 224);
    border-radius: 3rem;
  }
  &__dropdown::-webkit-scrollbar-thumb {
    background-color: $color-primary;
    border-radius: 3rem;
  }
  &__selected-sources {
    margin: 2.8rem 0 4rem;
    p {
      font-size: 1.4rem;
      font-family: $font-primary-bold;
      margin-bottom: 1.6rem;
      width: fit-content;
    }
    &--select-container {
      overflow-y: auto;
      max-height: 8rem;
      height: 100%;
      position: relative;
      .message{
        font-family: $font-primary;
        text-align: center;
        width: 100%;
        margin-bottom: 0;
      }
      .select {
        box-sizing: border-box;
        margin: 0 0.8rem 0.8rem 0.4rem;
        border-radius: 100px !important;
        color: #000000d9;
        font-variant: tabular-nums;
        list-style: none;
        font-feature-settings: tnum;
        display: inline-block;
        height: 3rem;
        padding: 0.5rem 1.6rem;
        white-space: nowrap;
        background-color: #d0dbff;
        border: 1px solid #3764fb;
        opacity: 1;
        transition: all 0.3s;
        position: relative;
        padding-right: 3rem;
        overflow: hidden;
        .source {
          display: flex;
          align-items: center;
          .logo{ 
            width: 1.5rem;
            height: 1.5rem;
            margin-right: 0.5rem;
            object-fit: contain;
            border-radius: none;
          }
          .border-radius {
            border-radius: 100%;
            width: 1.7rem;
            height: 1.7rem;
            margin-bottom: 0.2rem;
          }
          p {
            margin-right: 0.5rem;
            margin-bottom: 0;
            font-size: 1.2rem;
            font-family: $font-primary;
            line-height: 1.4rem;
          }
        }
        
        .icon {
          padding: 0.5rem;
          background-color: $color-white;
          border-radius: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 0.8rem;
          transform: rotate(45deg);
          cursor: pointer;
          width: fit-content;
          top: 50%;
          position: absolute;
          transform: translateY(-50%) rotate(45deg);
          right: 0.8rem;
          width: 1.8rem;
          height: 1.8rem;
          margin: 0;
        }
      }
      .close-btn {
        border: none;
        padding: 0.2rem;
        outline: none;
        background-color: $color-white;
        width: fit-content;
        height: fit-content;
        display: flex;
        align-items: center;
        justify-content: center;
        position: sticky;
        right: 1rem;
        top: 1rem;
        cursor: pointer;
        z-index: 1;
        .icon {
          font-size: 10px;
          color: $color-black;
          transform: rotate(45deg);
        }
      }
    }
    &--select-container::-webkit-scrollbar-track {
      background-color: #ffffff3f;
    }
    &--select-container::-webkit-scrollbar {
      width: 4px;
      background-color: rgb(224, 224, 224);
      border-radius: 3rem;
    }
    &--select-container::-webkit-scrollbar-thumb {
      background-color: $color-primary;
      border-radius: 3rem;
    }
    .select-sources-container {
      padding: 1.5rem 1rem 0.7rem !important;
      border: 1px solid $color-dark-grey-5;
      border-radius: 5px;
    }
  }
}
</style>
