<template>
  <div class="sources-filter-container">
    <a-tabs v-model:activeKey="activeKey">
      <a-tab-pane key="1">
        <template #tab>
          <span class="tabs-name">News Sources</span>
        </template>
        <div class="sources-filter-container-body">
          <div class="sources-filter-container-body__input-field">
            <p class="title">Key Words To Include</p>
            <pdb-words-filter
              :type="'include'"
              :prevTags="PDBIncludeKeywords"
              @fetchInlcude="getPDBIncludeKeywords"
            ></pdb-words-filter>
          </div>
          <div class="sources-filter-container-body__input-field">
            <p class="title">Key Words To Exclude</p>
            <pdb-words-filter
              :type="'exclude'"
              :prevTags="PDBExcludeKeywords"
              @fetchExclude="getPDBExcludeKeywords"
            ></pdb-words-filter>
          </div>
          <div class="sources-filter-container-body__input-field">
            <p class="title">Exact Match</p>
            <a-input
              v-model:value="newsExactMatch"
              placeholder="Exact Match"
            ></a-input>
          </div>
          <div class="sources-filter-container-body__input-field">
            <p class="title">Date Range</p>
            <pdb-date-filters> </pdb-date-filters>
          </div>
          <div class="sources-filter-container-body__input-field">
            <p class="title">Include Sources</p>
            <pdb-news-sources
              :source="'include'"
              :closeDropDown="closeDropDown"
            ></pdb-news-sources>
          </div>
          <div class="sources-filter-container-body__input-field">
            <p class="title">Exclude Sources</p>
            <pdb-news-sources
              :source="'exclude'"
              :closeDropDown="closeDropDown"
            ></pdb-news-sources>
          </div>
        </div>
        <div class="sources-filter-container-footer">
          <button
            @click="clearNewsFilters()"
            class="sources-filter-container-footer__btn sources-filter-container-footer__clear-filter-btn"
          >
            Clear Filter
          </button>
          <button
            @click="searchNewsArticles()"
            class="sources-filter-container-footer__btn"
          >
            Apply Filter
          </button>
        </div>
      </a-tab-pane>
      <a-tab-pane key="2">
        <template #tab>
          <span class="tabs-name">Research Publications</span>
        </template>
        <div class="sources-filter-container-body">
          <div class="sources-filter-container-body__input-field">
            <p class="title">Key Words To Include</p>
            <insights-words-filter
              :type="'include'"
              :prevTags="insightsIncludeKeywords"
              @fetchInlcude="getInsightsIncludeKeywords"
            ></insights-words-filter>
          </div>
          <div class="sources-filter-container-body__input-field">
            <p class="title">Key Words To Exclude</p>
            <insights-words-filter
              :type="'exclude'"
              :prevTags="insightsExcludeKeywords"
              @fetchExclude="getInsightsExcludeKeywords"
            ></insights-words-filter>
          </div>
          <div class="sources-filter-container-body__input-field">
            <p class="title">Exact Match</p>
            <a-input
              v-model:value="researchExactMatch"
              placeholder="Exact Match"
            ></a-input>
          </div>
          <div class="sources-filter-container-body__input-field">
            <p class="title">Date Range</p>
            <insights-date-filters> </insights-date-filters>
          </div>
          <div class="sources-filter-container-body__input-field">
            <p class="title">Included Sources</p>
            <insights-news-sources
              :source="'include'"
              :closeDropDown="closeDropDown"
            >
            </insights-news-sources>
          </div>
          <div class="sources-filter-container-body__input-field">
            <p class="title">Excluded Sources</p>
            <insights-news-sources
              :source="'exclude'"
              :closeDropDown="closeDropDown"
            >
            </insights-news-sources>
          </div>
        </div>
        <div class="sources-filter-container-footer">
          <button
            @click="clearInsightsFilters()"
            class="sources-filter-container-footer__btn sources-filter-container-footer__clear-filter-btn"
          >
            Clear Filter
          </button>
          <button
            @click="searchInsightsArticles()"
            class="sources-filter-container-footer__btn"
          >
            Apply Filter
          </button>
        </div>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
import PdbWordsFilter from "./PDBWordsFilter.vue";
import InsightsWordsFilter from "./InsightsWordsFilter.vue";
import PdbNewsSources from "./PDBNewsSources.vue";
import InsightsNewsSources from "./InsightsNewsSources.vue";
import PdbDateFilters from "./dateFilters/PDBDateFilters.vue";
import InsightsDateFilters from "./dateFilters/InsightsDateFilters.vue";
import { mapGetters, mapActions } from 'vuex'
export default {
  emits: ["closeDrawer"],
  props: {
    visibleDrawer: Boolean,
  },
  components: {
    PdbNewsSources,
    PdbDateFilters,
    InsightsDateFilters,
    InsightsNewsSources,
    PdbWordsFilter,
    InsightsWordsFilter
  },
  data() {
    return {
      activeKey: '1',
      closeDropDown: false,
      isPdbNullify: false,
      emptyTagifyInput: false
    }
  },
  computed: {
    ...mapGetters({
      selectedModule: 'discover/selectedModule',
      activeTopicTab: 'discover/activeTopicTab',
      getTopicDetails: 'discover/getTopicDetails',
      trendingNewsFilters: 'discover/trendingNewsFilters',
      trendingResearchFilter: 'discover/trendingResearchFilter',
      pdbExactKeyword: 'discover/pdbExactKeyword',
      insightExactKeyword: 'discover/insightExactKeyword',
    }),
    getSelectedNewIncludeSources() {
      return this.$store.getters['discover/getIncludedSelectedSources']; 
    },
    getSelectedNewsExcludeSources() {
      return this.$store.getters['discover/getExcludedSelectedSources'];
    },
    getSelectedInsightsIncludeSources() {
      return this.$store.getters['discover/getInsightsIncludedSelectedSources'];
    },
    getSelectedInsightsExcludeSources() {
      return this.$store.getters['discover/getInsightsExcludedSelectedSources'];
    },
    PDBIncludeKeywords() {
      return this.activeTopicTab === 'Trending news' ? this.trendingNewsFilters?.includedKeywords : this.$store.getters['discover/getPDBIncludedKeywords']; 
    },
    PDBExcludeKeywords() {
      return this.activeTopicTab === 'Trending news' ? this.trendingNewsFilters?.excludedKeywords : this.$store.getters['discover/getPDBExcludedKeywords'];
    },
    insightsIncludeKeywords() {
      return this.activeTopicTab === 'Trending news' ? this.trendingResearchFilter?.includedKeywords :  this.$store.getters['discover/getInsightsIncludedKeywords'];
    },
    insightsExcludeKeywords() {
      return this.activeTopicTab === 'Trending news' ? this.trendingResearchFilter?.excludedKeywords :  this.$store.getters['discover/getInsightsExcludedKeywords'];
    },
    newsExactMatch:{
      get(){
        return this.activeTopicTab === 'Trending news' ? this.trendingNewsFilters?.exactKeyword :  this.pdbExactKeyword
      },
      set(keyword){
        this.activeTopicTab === 'Trending news' ? this.setTrendingNewFilters({exactKeyword: keyword}) : this.setPDBExactKeyWord(keyword)
      }
    },
    researchExactMatch:{
      get(){
        return this.activeTopicTab === 'Trending news' ? this.trendingResearchFilter?.exactKeyword :  this.insightExactKeyword
      },
      set(keyword){
        this.activeTopicTab === 'Trending news' ? this.setTrendingResearchFilters({exactKeyword: keyword}) : this.setInsightsExactKeyWord(keyword)
      }
    }
  },
  mounted() {
    window.addEventListener('keyup', (e) => {
      if (e.key == 'Escape') {
        if (this.activeKey === '1' &&
          Object.keys(this.getSelectedNewIncludeSources).length > 0 ||
          Object.keys(this.getSelectedNewsExcludeSources).length > 0) {
          this.searchNewsArticles();
        } else if (this.activeKey === '2' &&
          Object.keys(this.getSelectedInsightsIncludeSources).length > 0 ||
          Object.keys(this.getSelectedInsightsExcludeSources).length > 0) {
          this.searchInsightsArticles();
        }
      }
    });
  },
  watch:{
    selectedModule:{
      handler(val){
          this.activeKey = val === 'Trending News' || val === 'news-tab' ? '1' :'2'
      },
      immediate:true
    }
  },
  methods: {
    ...mapActions({
      setTrendingNewFilters: 'discover/setTrendingNewFilters',
      setTrendingResearchFilters: 'discover/setTrendingResearchFilters',
      emptyTrendingNewsFilters: 'discover/emptyTrendingNewsFilters',
      setActiveTopic: 'discover/setActiveTopic',
      fetchAppliedFilters: 'discover/fetchAppliedFilters',
      setPDBExactKeyWord: 'discover/setPDBExactKeyWord',
      setInsightsExactKeyWord: 'discover/setInsightsExactKeyWord'
    }),
    async searchNewsArticles() {
      this.closeFilterDrawer();
      if (this.activeTopicTab !== 'Trending news' && this.getTopicDetails && this.getTopicDetails?.interest?.is_private){
        const appliedTopicFilters = await this.fetchAppliedFilters('topic-news')
        appliedTopicFilters['filter_for'] = 'news' // default value as news for news module
        await this.$store.dispatch('profile/updateInterestAppliedFilters', {id:this.getTopicDetails?.interest?.id ,filter:appliedTopicFilters});
        this.$store.dispatch('profile/getUserTopicsWithOrdering')
      }
      this.$store.commit("discover/SET_ARTICLES_PAGE_OFFSET", 0);
      await this.$store.dispatch("discover/searchArticles");
    },
    async searchInsightsArticles() {
      this.closeFilterDrawer();
      this.$store.commit("discover/SET_INSIGHTS_PAGE_OFFSET", 0);
      if (this.activeTopicTab !== 'Trending news' && this.getTopicDetails && this.getTopicDetails?.interest?.is_private){
      const appliedTopicFilters = await this.fetchAppliedFilters('topic-research')
        appliedTopicFilters['filter_for'] = 'research' // default value as research for research module
        await this.$store.dispatch('profile/updateInterestAppliedFilters', {id:this.getTopicDetails?.interest?.id ,filter:appliedTopicFilters});
        this.$store.dispatch('profile/getUserTopicsWithOrdering')
      }
      await this.$store.dispatch("discover/searchInsights");
    },
    clearInsightsFilters() {
      this.emitter.emit("clearInsightsFilters", true);
      this.emitter.emit()
      if (this.activeTopicTab === 'Trending news') {
        this.emptyTrendingNewsFilters('trending-research')
      }
      else{
        this.$store.commit("discover/SET_INSIGHTS_INCLUDED_SOURCES", [])
        this.$store.commit("discover/SET_INSIGHTS_EXCLUDED_SOURCES", []);
        this.$store.commit("discover/INSIGHTS_INCLUDED_KEYWORDS", []);
        this.$store.commit("discover/INSIGHTS_EXCLUDED_KEYWORDS", []);
        this.setInsightsExactKeyWord('')
      }
      this.$store.commit('discover/SET_FILTERS_COUNT', 0);
      this.$store.dispatch("discover/getAllInsightsSources");
      this.searchInsightsArticles();
    },
    clearNewsFilters() {
      this.emitter.emit("clearNewsFilters", true);
      if (this.activeTopicTab === 'Trending news') {
        this.emptyTrendingNewsFilters()
      }
      else{
        this.$store.commit("discover/SET_INCLUDED_SOURCES", []);
        this.$store.commit("discover/SET_EXCLUDED_SOURCES", []);
        this.$store.commit("discover/PDB_INCLUDED_KEYWORDS", []);
        this.$store.commit("discover/PDB_EXCLUDED_KEYWORDS", []);
        this.setPDBExactKeyWord()
      }
      this.$store.commit('discover/SET_FILTERS_COUNT', 0);
      this.$store.dispatch("discover/getAllSources");
      this.searchNewsArticles();
    },
    closeFilterDrawer() {
      this.$emit("closeDrawer");
      this.emitter.emit("closeFilterSideDrawer", false);
    },
    getPDBIncludeKeywords(val) {
      this.activeTopicTab === 'Trending news' ? this.setTrendingNewFilters({includedKeywords: val}) : this.$store.commit('discover/PDB_INCLUDED_KEYWORDS', val);
    },
    getPDBExcludeKeywords(val) {
      this.activeTopicTab === 'Trending news' ? this.setTrendingNewFilters({excludedKeywords: val}) : this.$store.commit('discover/PDB_EXCLUDED_KEYWORDS', val);
    },
    getInsightsIncludeKeywords(val) {
      this.activeTopicTab === 'Trending news' ? this.setTrendingResearchFilters({includedKeywords: val}) : this.$store.commit('discover/INSIGHTS_INCLUDED_KEYWORDS', val);
    },
    getInsightsExcludeKeywords(val) {
      this.activeTopicTab === 'Trending news' ? this.setTrendingResearchFilters({excludedKeywords: val}) : this.$store.commit('discover/INSIGHTS_EXCLUDED_KEYWORDS', val);
    },
  }
};
</script>

<style lang="scss">
.sources-filter-container {
  position: relative;
  height: 100vh;
  .ant-tabs {
    height: 100%;
    padding: 0;
    .ant-tabs-bar {
      border: none;
      margin-bottom: 0;
      .ant-tabs-nav-container {
        padding-bottom: 2px;
        .ant-tabs-tab-arrow-show {
          display: none !important;
        }
        .ant-tabs-nav-wrap {
          border: none;
          .ant-tabs-nav-scroll {
            display: none;
            border: none;
            overflow-x: auto;
            margin: 0 2.5rem;
            &::-webkit-scrollbar {
              height: 0.3rem;
            }
            &::-webkit-scrollbar-track {
              background-color: $color-white;
              border-radius: 0.8rem;
              height: 0.3rem;
            }

            &::-webkit-scrollbar-thumb {
              background-color: $color-primary;
              border-radius: 0.8rem;
            }
            @include respond(phone-x-small) {
              &::-webkit-scrollbar {
                display: none;
              }
            }
            .ant-tabs-nav {
              border: none;
              padding-bottom: 0;
              .ant-tabs-tab {
                padding: 0.6rem 2rem;
                border-radius: 100px;
                font-size: 1.4rem;
                border: 1px solid $color-dark-grey-5;
                font-family: $font-primary;
                color: #434343;
                margin-right: 1.2rem;
                margin-bottom: 0.2rem;
              }
              .ant-tabs-tab-active {
                font-family: $font-primary-regular;
                background-color: $color-dark-grey-5;
                color: $color-black;
                opacity: 1;
              }
              .ant-tabs-ink-bar {
                display: none;
              }
            }
            .ant-tabs-ink-bar {
              display: none !important;
            }
          }
        }
      }
    }
    .ant-tabs-content {
      .ant-tabs-tabpane {
        height: 100%;
        overflow-x: hidden;
        .sources-filter-container-body {
          padding: 0 2.5rem;
          overflow-y: auto;
          height: calc(100vh - 21.5rem);
          overflow-x: hidden;
          &__input-field {
            margin-bottom: 3rem;
            .title {
              font-size: 1.4rem;
              color: $color-black;
              font-family: $font-primary-medium;
              font-weight: 500;
              margin-bottom: 1.6rem;
              line-height: 1.6rem;
              width: fit-content;
            }
            input {
              border-radius: 5px !important;
              font-size: 1.4rem;
              border: 1px solid #c5c6cc;
              font-family: $font-primary;
              width: 100%;
              height: 4rem;
              padding: 1rem;
              outline: none;
              color: $color-black;
              &::placeholder {
                opacity: 0.4;
                color: $color-black;
              }
            }
            .all-news-sources-dropdown {
              &__dropdown {
                border: 1px solid $color-dark-grey-5;
                border-top: none;
              }
              &__selected-sources {
                margin-top: 2rem;
                p {
                  color: $color-black;
                }
                .message {
                  opacity: 0.8;
                }
                &--select-container {
                  max-height: 100%;
                }
              }
            }
            .pdb-news-sources-date-filters,
            .pdb-reserch-date-filters {
              .end-date {
                margin-bottom: 0;
              }
            }
          }
          &::-webkit-scrollbar-track {
            background-color: #ffffff3f;
          }
          &::-webkit-scrollbar {
            width: 4px;
            background-color: rgb(224, 224, 224);
            border-radius: 3rem;
          }
          &::-webkit-scrollbar-thumb {
            background-color: $color-primary;
            border-radius: 3rem;
          }
        }
        .sources-filter-container-footer {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          padding: 2rem 2.5rem;
          &__btn {
            border: none;
            outline: none;
            background-color: $color-primary;
            font-size: 1.5rem;
            color: $color-white;
            width: max-content;
            padding: 0 4rem;
            height: 4rem;
            font-family: $font-primary-medium;
            margin-left: 0;
            cursor: pointer;
            display: block;
          }
          &__clear-filter-btn {
            background-color: $color-dark-grey-5;
            color: $color-black;
            margin-right: 1rem;
          }
        }
      }
    }
  }
}
</style>
